import AsideMenu from './components/AsideMenu';
import LocalTime from './components/LocalTime';
import Hero from './components/Hero';
import UnderConstruction from './components/UnderConstruction';

export default function App() {
    const underConstruction = true;
    if (underConstruction) {
        return (
            <UnderConstruction></UnderConstruction>
        );
    } else
    return (
        <>
            <div className="rounded-b-3xl">
                <AsideMenu />
                <LocalTime />
                <Hero />
            </div>
            <div className="bg-black h-96 w-screen"></div>
        </>
    );
}
