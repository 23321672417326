import React, { useEffect, useRef } from 'react';
import anime from 'animejs/lib/anime.es.js';
import Letterize from 'letterizejs';

const colorPicker = (() => {
    const colors = ['#FF6138', '#FFBE53', '#2980B9', '#282741'];
    let index = 0;

    function next() {
        index = index++ < colors.length - 1 ? index : 0;
        return colors[index];
    }

    function current() {
        return colors[index];
    }

    return {
        next: next,
        current: current,
    };
})();
const UnderConstruction = () => {
    const canvasRef = useRef(null);

    useEffect(() => {
        let c, ctx, cH, cW, bgColor, animations, circles;
        let textAnimation;

        function removeAnimation(animation) {
            const index = animations.indexOf(animation);
            if (index > -1) animations.splice(index, 1);
        }

        function calcPageFillRadius(x, y) {
            const l = Math.max(x - 0, cW - x);
            const h = Math.max(y - 0, cH - y);
            return Math.sqrt(Math.pow(l, 2) + Math.pow(h, 2));
        }

        function handleEvent(e) {
            if (e.touches) {
                e.preventDefault();
                e = e.touches[0];
            }
            const currentColor = colorPicker.current();
            const nextColor = colorPicker.next();
            const targetR = calcPageFillRadius(e.pageX, e.pageY);
            const rippleSize = Math.min(200, cW * 0.4);
            const minCoverDuration = 750;

            const pageFill = new Circle({
                x: e.pageX,
                y: e.pageY,
                r: 0,
                fill: nextColor,
            });
            const fillAnimation = anime({
                targets: pageFill,
                r: targetR,
                duration: Math.max(targetR / 2, minCoverDuration),
                easing: 'easeOutQuart',
                complete: function () {
                    bgColor = pageFill.fill;
                    c.style.backgroundColor = pageFill.fill; // Set the canvas background color
                    removeAnimation(fillAnimation);
                },
            });

            const ripple = new Circle({
                x: e.pageX,
                y: e.pageY,
                r: 0,
                fill: currentColor,
                stroke: {
                    width: 3,
                    color: currentColor,
                },
                opacity: 1,
            });
            const rippleAnimation = anime({
                targets: ripple,
                r: rippleSize,
                opacity: 0,
                easing: 'easeOutExpo',
                duration: 900,
                complete: removeAnimation,
            });

            const particles = [];
            for (let i = 0; i < 32; i++) {
                const particle = new Circle({
                    x: e.pageX,
                    y: e.pageY,
                    fill: currentColor,
                    r: anime.random(24, 48),
                });
                particles.push(particle);
            }
            const particlesAnimation = anime({
                targets: particles,
                x: function (particle) {
                    return particle.x + anime.random(rippleSize, -rippleSize);
                },
                y: function (particle) {
                    return particle.y + anime.random(rippleSize * 1.15, -rippleSize * 1.15);
                },
                r: 0,
                easing: 'easeOutExpo',
                duration: anime.random(1000, 1300),
                complete: removeAnimation,
            });
            animations.push(fillAnimation, rippleAnimation, particlesAnimation);
        }

        function extend(a, b) {
            for (const key in b) {
                if (b.hasOwnProperty(key)) {
                    a[key] = b[key];
                }
            }
            return a;
        }

        const Circle = function (opts) {
            extend(this, opts);
        };

        Circle.prototype.draw = function () {
            ctx.globalAlpha = this.opacity || 1;
            ctx.beginPath();
            ctx.arc(this.x, this.y, this.r, 0, 2 * Math.PI, false);
            if (this.stroke) {
                ctx.strokeStyle = this.stroke.color;
                ctx.lineWidth = this.stroke.width;
                ctx.stroke();
            }
            if (this.fill) {
                ctx.fillStyle = this.fill;
                ctx.fill();
            }
            ctx.closePath();
            ctx.globalAlpha = 1;
        };

        const animate = anime({
            duration: Infinity,
            update: function () {
                // Clear canvas
                ctx.clearRect(0, 0, c.width, c.height);

                // Draw other animations
                animations.forEach(function (anim) {
                    anim.animatables.forEach(function (animatable) {
                        animatable.target.draw();
                    });
                });
            },
        });

        const resizeCanvas = function () {
            cW = window.innerWidth;
            cH = window.innerHeight;
            c.width = cW * devicePixelRatio;
            c.height = cH * devicePixelRatio;
            ctx.scale(devicePixelRatio, devicePixelRatio);
        };

        const init = () => {
            c = canvasRef.current;
            ctx = c.getContext('2d');
            cW = window.innerWidth;
            cH = window.innerHeight;
            bgColor = '#FF6138';
            animations = [];
            circles = [];

            resizeCanvas();
            window.addEventListener('resize', resizeCanvas);
            addClickListeners();
            if (!!window.location.pathname.match(/fullcpgrid/)) {
                startFauxClicking();
            }
            handleInactiveUser();

            const test = new Letterize({
                targets: '.animate-me',
            });

            textAnimation = anime.timeline({
                targets: test.listAll,
                delay: anime.stagger(100, {
                    grid: [test.list[0].length, test.list.length],
                    from: 'center',
                }),
                direction: 'alternate',
                loop: true,
                easing: 'easeInOutQuad',
            })
                .add({
                    scale: 0.5,
                    duration: 500,
                })
                .add({
                    letterSpacing: '10px',
                    duration: 500,
                })
                .add({
                    scale: 1,
                    duration: 500,
                })
                .add({
                    letterSpacing: '6px',
                    duration: 500,
                });


            // Start the canvas animation
            animate.play();
        };

        const addClickListeners = () => {
            document.addEventListener('touchstart', handleEvent);
            document.addEventListener('mousedown', handleEvent);
        };

        const handleInactiveUser = () => {
            const inactive = setTimeout(function () {
                fauxClick(cW / 2, cH / 2);
            }, 2000);

            const clearInactiveTimeout = () => {
                clearTimeout(inactive);
                document.removeEventListener('mousedown', clearInactiveTimeout);
                document.removeEventListener('touchstart', clearInactiveTimeout);
            };

            document.addEventListener('mousedown', clearInactiveTimeout);
            document.addEventListener('touchstart', clearInactiveTimeout);
        };

        const startFauxClicking = () => {
            setTimeout(function () {
                fauxClick(anime.random(cW * 0.2, cW * 0.8), anime.random(cH * 0.2, cH * 0.8));
                startFauxClicking();
            }, anime.random(200, 900));
        };

        const fauxClick = (x, y) => {
            const fauxClick = new Event('mousedown');
            fauxClick.pageX = x;
            fauxClick.pageY = y;
            document.dispatchEvent(fauxClick);
        };

        init();

        return () => {
            document.removeEventListener('touchstart', handleEvent);
            document.removeEventListener('mousedown', handleEvent);
            window.removeEventListener('resize', resizeCanvas);
            textAnimation.pause();
        };
    }, []);

    return (
        <div style={{ position: 'relative' }}>
            <canvas
                ref={canvasRef}
                id="c"
                className="flex w-screen h-screen"
            />
            <div
                className="flex w-screen h-screen flex-col justify-center items-center text-3xl font-normal leading-tight uppercase select-none"
                style={{ position: 'absolute', top: 0, left: 0 }}
            >
                <div className="text-sm sm:text-base md:text-lg lg:text-xl xl:text-2xl animate-me tracking-[6px]">
                    This site is under construction
                </div>
                <div className="text-sm sm:text-base md:text-lg lg:text-xl xl:text-2xl animate-me tracking-[6px]">
                    under construction This site is
                </div>
                <div className="text-sm sm:text-base md:text-lg lg:text-xl xl:text-2xl animate-me tracking-[6px]">
                    This site is under construction
                </div>
                <div className="text-sm sm:text-base md:text-lg lg:text-xl xl:text-2xl animate-me tracking-[6px]">
                    under construction This site is
                </div>
                <div className="text-sm sm:text-base md:text-lg lg:text-xl xl:text-2xl animate-me tracking-[6px]">
                    This site is under construction
                </div>
                <div className="text-sm sm:text-base md:text-lg lg:text-xl xl:text-2xl animate-me tracking-[6px]">
                    under construction This site is
                </div>
                <div className="text-sm sm:text-base md:text-lg lg:text-xl xl:text-2xl animate-me tracking-[6px]">
                    This site is under construction
                </div>
                <div className="text-sm sm:text-base md:text-lg lg:text-xl xl:text-2xl animate-me tracking-[6px]">
                    under construction This site is
                </div>
                <div className="text-sm sm:text-base md:text-lg lg:text-xl xl:text-2xl animate-me tracking-[6px]">
                    This site is under construction
                </div>
                <div className="text-sm sm:text-base md:text-lg lg:text-xl xl:text-2xl animate-me tracking-[6px]">
                    under construction This site is
                </div>
                <div className="text-sm sm:text-base md:text-lg lg:text-xl xl:text-2xl animate-me tracking-[6px]">
                    This site is under construction
                </div>
                <div className="text-sm sm:text-base md:text-lg lg:text-xl xl:text-2xl animate-me tracking-[6px]">
                    under construction This site is
                </div>
                <div className="text-sm sm:text-base md:text-lg lg:text-xl xl:text-2xl animate-me tracking-[6px]">
                    This site is under construction
                </div>
                <div className="text-sm sm:text-base md:text-lg lg:text-xl xl:text-2xl animate-me tracking-[6px]">
                    under construction This site is
                </div>
            </div>
        </div>
    );
};

export default UnderConstruction;
